import React, { Component } from 'react'
import '../css/dock.css'

export default class Dock extends Component {
	constructor(props) {
        super(props);
        this.state = {
          time: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}),
          logout: props.logout
        };
      }

	componentDidMount() {
        this.intervalID = setInterval(
          () => this.tick(),
          1000
        );
      }
      componentWillUnmount() {
        clearInterval(this.intervalID);
      }
      tick() {
        this.setState({
          time: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
        });
      }
	render() {


		return(

			


			<div className="dock-container">
        <div>{this.state.logout === "true" && <a href="/"  onClick={this.props.clickLogout} > </a>}</div>
				<p className="App-clock">{this.state.time}</p>
				
			</div>)

	}
}