import React, { Component } from 'react'
import DesktopIcon from '../components/DesktopIcon'
import ImageViewerDeviantTracker from '../components/ImageViewerDeviantTracker'
export default class ImageDeviantTrackerView extends Component {

    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this)
        this.state = {
          images:this.props.icon.images,
          selectedImage: null,
        };

    }


    componentDidMount() {
        this.updateDimensions()
        window.addEventListener("resize", this.updateDimensions);
    }


     componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions);
    };

    updateDimensions() {

        var newIcons = this.state.images
        if (window.innerWidth < 800) {
            let i = 0
            let top = 10
            let left = 0
            newIcons.forEach(icon => {

                if (i % 3 === 0) {
                  if (i !== 0) {
                    top += 104
                  }
                  left = 0
                } else {
                  left += 105
                }
                icon.top = top
                icon.left = left

                i++
           
              //console.log("after "+i)
            })
            this.setState({images:newIcons});
        } else {
          let i = 0
          let top = 10
          let left = 10
          newIcons.forEach(icon => {
            
              if (i % 5 === 0) {
                if (i !== 0) {
                  top += 120
                }
                left = 10
              } else {
                left += 105
              }
              icon.top = top
              icon.left = left

              i++;
            

          })
          this.setState({images:newIcons});
        } 
    }


    handleClickIcon = e => {
        //console.log("handleClickIcon")


        var imageIndex =  parseInt(e.target.id.substring(5, e.target.id.length));
       //console.log(iconIndex)
       //console.log(this.state.icons)
       //console.log("OPEN OBJECT "+iconIndex)
       let image = this.state.images[imageIndex]

       //console.log(image)


       this.setState({selectedImage:image});
       


    }


    goBack = (e) => {
         this.setState({selectedImage:null});
    };

    render() {

        const   icons   = this.state.images

        const iconviews = icons.map((icon, index) => {
            return (
                  <DesktopIcon icon={icon} key={index} iconSingleClick={this.handleClickIcon.bind(this)} /> 
                )
         })

        

        return (<div className="images-container" > 
        	<h4>Images</h4>
        	<h2>{this.props.icon.name}</h2>


        	
                
                {this.state.selectedImage != null  && (<div className="relative">
                    <div className="gallery-viewer__back" onClick={this.goBack}>
                        <span className="gallery-viewer__back-arrow">&lt;</span>
                        <span className="gallery-viewer__back-text">GO BACK</span>
                    </div>

                    <ImageViewerDeviantTracker initialWidthPercent="100" image={this.state.selectedImage} />
                </div>)}

                {this.state.selectedImage === null && (<div className="relative">
                    <div className="gallery-viewer__back" onClick={this.props.goBack}>
                        <span className="gallery-viewer__back-arrow">&lt;</span>
                        <span className="gallery-viewer__back-text">GO BACK</span>
                    </div>
                    {iconviews} 

                    </div>) }



        	

            

        	</div>)
    }




}
