import React, { Component } from 'react'

export default class LoginDeviantTrackerFolder extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      password: "",
	      error: false,
	    };
	}

	

  	handleChange = event => {
  		
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	 }

	handleSubmit = event => {
		//console.log("submit")
	    event.preventDefault();
	    const password = this.state.password;
	    this.setState({ error: false });

	    //console.log(this.props.icon.password)
	    //console.log(password)

	    if (typeof this.props.icon.password !== 'undefined') {
	    	if(password.toLowerCase() !== this.props.icon.password.toLowerCase()){
		    	return this.setState({ error: true });
		    }else{
		    	this.props.openIcon(this.props.icon)
		    }

	    }else{
	    	return this.setState({ error: true });
	    }
	}

	handleKeyPress = (event) => {
	  if(event.key === 'Enter'){
	    //console.log('enter press here! ')
	    document.activeElement.blur();
	    event.preventDefault();
	  }
	}


    render() {

        return (<div className="login-dt-folder h-full w-full flex justify-center items-center z-10"  onMouseDown={this.props.tapBack}>



                	<div className="login-box rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
                	<div className="mr-2 "><img onMouseDown={this.props.tapBack} className=" close-icon-modal close-dtfolderlogin"  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s5/dt-close-button.svg"/></div>
		        	
			        	<h1> {this.props.icon.name}</h1>
			        	

			        	<form className=" rounded  pt-6 " onSubmit={this.handleSubmit}>
			        		<div className="">
			        			
	              				<div className=" mb-3">
				        			<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password}
		              onChange={this.handleChange} type="password" autoFocus id="password" placeholder="Password" onKeyPress={this.handleKeyPress} autoComplete="on" />
		              				<p className="error mt-2">{this.state.error && "Oops! Something is wrong."} &nbsp;</p>
	              				</div>
			        		</div>
			        		<div className="flex items-center justify-between">
						      <button className="bg-grey mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" id="dtFolderLoginButton" >
						        ENTER
						      </button>
						     
						    </div>
			        	</form>

			        </div>




                </div>)
    }




}
