import React, { Component } from 'react'
import Div100vh from 'react-div-100vh'
import 'react-perfect-scrollbar/dist/css/styles.css';
//import PerfectScrollbar from 'react-perfect-scrollbar'
//import './css/scrollbar.css'

import store from 'store';

import EnterEmailView from './components/inbox/EnterEmailView'


import { Player } from './models/player';

import EmailClient from './components/EmailClient/email-client';
//import ChatClient from './components/ChatClient/chat-client';

import { sendKlaviyoEvent } from './utils/klaviyo.js';
//var getCurrentSubject = require('./utils/emailUtils.js').getCurrentSubject;


export default class Inbox extends Component {
    constructor(props) {
        super(props);


        this.currentSeasonIndex = this.props.currentSeason
        this.currentEpisodeIndex = this.props.currentEpisode
       // this.subject = getCurrentSubject(this.currentSeasonIndex, this.currentEpisodeIndex)
        this.subject= ""
        const userEmail = store.get("userEmails5");

        console.log(this.currentSeasonIndex)
        console.log(this.currentEpisodeIndex)
        this.state = {
            showEmailClient: true,
            userEmail:userEmail,
        };

    }

    closeModal = (e) => {
        this.props.tapBack()
    };


    closeEmailPopUp = (email) => {
        this.setState({ userEmail:email})
    }

    handleBotEmailResponse = (botResponse) => {
        //console.log(botResponse)
        const correctQuestion = botResponse.indexOf('{{CorrectQuestion}}');
        if (correctQuestion !== -1) {
            // Call API
            console.log('Correct');
            sendKlaviyoEvent(this.props.currentEpisode,this.state.userEmail)
            
            var event = new CustomEvent('CHARACTERRESPONSE', {detail :  { "season":"hak05", "episode":"0"+this.props.currentEpisode, "success":true } });
            window.dispatchEvent(event);
        

        } else {
            console.log('Incorrect');
        }
    }



    render() {
        // Render appointment list if active.
        // playerName and playerAvatar values are need to invoke client.
        const renderEmailClient = () => {

            if (this.state.showEmailClient) {
                const player = new Player();
                player.email = this.state.userEmail;
                player.name = '';
                player.avatar = '';
                const initialEmailSubject = this.subject;
                const configurationLocation = '/config/config.json';
                return <EmailClient player      = {player}
                    initialEmailSubject         = {initialEmailSubject}
                    configurationLocation       = {configurationLocation}
                    botEmailResponse            = {this.handleBotEmailResponse}
                />;
            }
            return null;
        };



        return (
          <Div100vh  dontresize="true" className="h-full w-full flex flex-col inbox">
                <div className="modalcontainer inboxcontainer w-full h-full">
                    <div className="header">

                        <h2>Contact</h2>< button className="close" onClick={this.closeModal}/>  
                    </div>
                    <div className="HAKEmailChatContainer  w-full h-full">
                     {this.state.userEmail  !== undefined && (renderEmailClient())}
                     {this.state.userEmail  === undefined && (<EnterEmailView closeCallback={this.closeEmailPopUp.bind(this)}/>)}
                    </div>

                 </div>


            </Div100vh>
        )
    }
}
