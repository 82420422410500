import React, { Component } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import '../css/scrollbar.css'
export default class ImageViewer extends Component {
	constructor(props) {
        super(props);
        this.state = {
          initialWidthPercent:parseInt(this.props.initialWidthPercent)
        };
      }

	componentDidMount() {

  }
  componentWillUnmount() {
  }


  zoomOut = (e) => {
     let widthPercent = this.state.initialWidthPercent;
     widthPercent = Math.max(widthPercent - 20, 100); 
     this.setState({initialWidthPercent:widthPercent});
  };


  zoomIn = (e) => {
     let widthPercent = this.state.initialWidthPercent;
     widthPercent = Math.min(widthPercent + 20, 350); 
     this.setState({initialWidthPercent:widthPercent});
  };

	render() {

    const contentFileStyle = {
      width:this.state.initialWidthPercent + "%"
    };

		return(
			<div className="imageViewer">
        <PerfectScrollbar>
          <div className="content-file" style={contentFileStyle}>
            <img src={"https://cdn.huntakiller.com/huntakiller/s5"+this.props.img_url} alt={this.props.img_name}/>
          </div>
        </PerfectScrollbar>
        <div className="bottom-imageViewer-controllers">
          <button onClick={this.zoomIn}>Zoom In</button>
          <button onClick={this.zoomOut}>Zoom Out</button>
          <a  href={"https://cdn.huntakiller.com/huntakiller/s5"+this.props.img_url} target="_blank" rel="noopener noreferrer" download={this.props.img_name}>Download</a>

        </div>
			</div>)

	}
}