import React, { Component } from 'react'
import store from 'store';
import { devianttracker } from '../datas/season5/devianttracker.json';
import CheeseburgerMenu from 'cheeseburger-menu'
import MenuContentDeviantTracker from '../components/MenuContentDeviantTracker'
import LoginDeviantTracker from '../components/LoginDeviantTracker'
import DesktopIcon from '../components/DesktopIcon'
import LoginDeviantTrackerFolder from '../components/LoginDeviantTrackerFolder'
import AudioDeviantTrackerView from './AudioDeviantTrackerView'
import DatabaseDeviantTrackerView from './DatabaseDeviantTrackerView'
import ShiftDecoderDeviantTrackerView from './ShiftDecoderDeviantTrackerView'
import ManualDecoderDeviantTrackerView from './ManualDecoderDeviantTrackerView'
import AutomaticDecoderDeviantTrackerView from './AutomaticDecoderDeviantTrackerView'
import ImageDeviantTrackerView from './ImageDeviantTrackerView'
import VideoDeviantTrackerView from './VideoDeviantTrackerView'


export default class DeviantTrackerView extends Component {

	constructor(props) {
	    super(props);
	    this.updateDimensions = this.updateDimensions.bind(this)
	    this.state = {
	      isUnlocked: false,
	      currentView:"Home",
	      menuOpen: false,
	      icons:[],
	      showPwModal: false,
	      selectedIconPw: null,
	      selectedIcon: null,
	    };

	    //console.log(devianttracker)

		const dtpassword = store.get("dtpassword") ? store.get("dtpassword") : "";
		if(dtpassword.toLowerCase() === devianttracker.password.toLowerCase() || !devianttracker.passwordprotected){
			this.state.isUnlocked = true
		}
	}


	openMenu() {
		this.setState({ menuOpen: true })
	}

	closeMenu() {
		this.setState({ menuOpen: false })
	}

	tapMenu(item) {
		//console.log("tapMenu")
		
		this.closeMenu()

		if(this.state.currentView !== item){
			//console.log("display view")
			//console.log(devianttracker[item])

			if (typeof devianttracker[item] !== 'undefined'){

				this.setState({
			        icons: devianttracker[item]
			    }, () => {
			     this.updateDimensions()
			    })

			}else{
				this.setState({ icons: [] })
			}


			
		}
		this.setState({selectedIcon:null})
		this.setState({ currentView: item })
		
	}

	validateLogin(){
		this.setState({ isUnlocked: true });
	}


	handleClickIcon = e => {
		//console.log("handleClickIcon")


		var iconIndex =  parseInt(e.target.id.substring(5, e.target.id.length));
       //console.log(iconIndex)
       //console.log(this.state.icons)
       //console.log("OPEN OBJECT "+iconIndex)
       let icon = this.state.icons[iconIndex]

       //console.log(icon)


       if (typeof icon.passwordprotected !== 'undefined'){
              //console.log("password protected")
              this.setState({showPwModal:true});
              this.setState({selectedIconPw:icon})
              
              return
        }else{
        	//console.log(icon)
        	this.setState({selectedIcon:icon})
        }


	}


	componentDidMount() {
		this.updateDimensions()
    	window.addEventListener("resize", this.updateDimensions);
  	}


     componentWillUnmount(){
        window.removeEventListener('resize', this.updateDimensions);
    };


    updateDimensions() {

		var newIcons = this.state.icons
	    if (window.innerWidth < 800) {
	        let i = 0
	        let top = 10
	        let left = 0
	        newIcons.forEach(icon => {

	            if (i % 3 === 0) {
	              if (i !== 0) {
	                top += 104
	              }
	              left = 0
	            } else {
	              left += 105
	            }
	            icon.top = top
	            icon.left = left

	            i++
	       
	          //console.log("after "+i)
	        })
	        this.setState({icons:newIcons});
        } else {
	      let i = 0
	      let top = 10
	      let left = 10
	      newIcons.forEach(icon => {
	        
	          if (i % 5 === 0) {
	            if (i !== 0) {
	              top += 120
	            }
	            left = 10
	          } else {
	            left += 105
	          }
	          icon.top = top
	          icon.left = left

	          i++;
	        

	      })
	      this.setState({icons:newIcons});
        } 
	}

	handleTapBackFolderPw = e => {
		//If tapped on the overlay bg

		if ( ( (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf("login-dt-folder") > -1 ) || ((" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf("close-dtfolderlogin") > -1)) {
			e.preventDefault()  
			this.setState({showPwModal:false});
			this.setState({selectedIconPw:null})
		}
     

	 }


	 goBack = e => {
	 	//console.log("BACKKK")
	 	this.setState({selectedIcon:null})
	 }


	openSelectedIconPw(icon){
    	//console.log("openSelectedIconPw")
    	//console.log(icon)

    	this.setState({showPwModal:false});
    	this.setState({selectedIconPw:null})
    	this.setState({selectedIcon:icon})

	}

    render() {

    	
    	const   icons   = this.state.icons

    	const iconviews = icons.map((icon, index) => {
	        return (
			      <DesktopIcon icon={icon} key={index} iconSingleClick={this.handleClickIcon.bind(this)} /> 
			    )
		 })

        return (
        	<div className="h-full w-full flex flex-col devianttracker"> 
	        	<div className="header flex justify-between items-center">
	        		<img className="menu-toggle ml-2 menu-icon-dt mobile" alt="menu-toggle" onClick={this.openMenu.bind(this)} src="https://cdn.huntakiller.com/huntakiller/s5/dt-hamburger.svg"/>
	        		<img className="logo" src="https://cdn.huntakiller.com/huntakiller/s5/logo_devianttracker.svg"  alt="logo-dt"/>
	        		<div className="desktop"><MenuContentDeviantTracker currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)}/></div>
	        		<div className="mr-2 "><img className=" close-icon "  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s5/dt-close-button.svg"/></div>
	        	</div>
	        	
	        	
	        	<CheeseburgerMenu
			        isOpen={this.state.menuOpen}
			        closeCallback={this.closeMenu.bind(this)}>
			        <MenuContentDeviantTracker currentView = {this.state.currentView} tapMenu={this.tapMenu.bind(this)}/>
		        </CheeseburgerMenu>


	        	<div className="deviant-container flex-1" >
	        		{this.state.currentView === "Home" && (
	        			<div>
	        				<h2>WELCOME!</h2>
	        				<p>DeviantTracker is the leading software for private investigators worldwide. With our focus on organization and security, we'll help you safely manage every aspect of your investigation.</p>
	        				<p>Version 6.4.5</p>
	        			</div>
	        		)}
	        		{(this.state.currentView !== "Home" && this.state.selectedIcon === null) && (<div><h2>{this.state.currentView}</h2><div className="relative">{iconviews}</div></div>)}

	        		{(this.state.selectedIcon != null && this.state.selectedIcon.type === "audio") && (<AudioDeviantTrackerView goBack={this.goBack.bind(this)} icon={this.state.selectedIcon}/>)}
	        		{(this.state.selectedIcon != null && this.state.selectedIcon.type === "database") && (<DatabaseDeviantTrackerView goBack={this.goBack.bind(this)}  icon={this.state.selectedIcon}/>)}
	        		{(this.state.selectedIcon != null && this.state.selectedIcon.type === "shift-decoder") && (<ShiftDecoderDeviantTrackerView goBack={this.goBack.bind(this)} icon={this.state.selectedIcon}/>)}
	        		{(this.state.selectedIcon != null && this.state.selectedIcon.type === "manual-decoder") && (<ManualDecoderDeviantTrackerView goBack={this.goBack.bind(this)} icon={this.state.selectedIcon}/>)}
	        		{(this.state.selectedIcon != null && this.state.selectedIcon.type === "automatic-decoder") && (<AutomaticDecoderDeviantTrackerView goBack={this.goBack.bind(this)} icon={this.state.selectedIcon}/>)}
	        		{(this.state.selectedIcon != null && this.state.selectedIcon.type === "image") && (<ImageDeviantTrackerView goBack={this.goBack.bind(this)} icon={this.state.selectedIcon}/>)}
	        		{(this.state.selectedIcon != null && this.state.selectedIcon.type === "video") && (<VideoDeviantTrackerView goBack={this.goBack.bind(this)} icon={this.state.selectedIcon}/>)}


	        		{this.state.showPwModal && (
		                <LoginDeviantTrackerFolder tapBack={this.handleTapBackFolderPw.bind(this)} icon={this.state.selectedIconPw} openIcon={this.openSelectedIconPw.bind(this)}/> 
		              )}

	        	</div>

	        	{!this.state.isUnlocked && (
                <LoginDeviantTracker tapBack={this.props.tapBack.bind(this)} validateLogin={this.validateLogin.bind(this)}/>
              	)}
        	

        	</div>)
    }
}
