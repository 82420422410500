import React, { Component } from 'react'
import '../css/scrollbar.css'
export default class ImageViewerDeviantTracker extends Component {
	constructor(props) {
        super(props);
        this.state = {
          initialWidthPercent:parseInt(this.props.initialWidthPercent)
        };
      }

	componentDidMount() {

  }
  componentWillUnmount() {
  }


  zoomOut = (e) => {
     let widthPercent = this.state.initialWidthPercent;
     widthPercent = Math.max(widthPercent - 20, 100); 
     this.setState({initialWidthPercent:widthPercent});
  };


  zoomIn = (e) => {
     let widthPercent = this.state.initialWidthPercent;
     widthPercent = Math.min(widthPercent + 20, 340); 
     this.setState({initialWidthPercent:widthPercent});
  };

	render() {

    const contentFileStyle = {
      width:this.state.initialWidthPercent + "%"
    }; 
		return(<div>
      {this.props.image.notes  && (<div className="notes-section pt-2 pb-10">{this.props.image.notes}</div>)}


			<div className="imageViewer">
          <div className="bottom-imageViewer-controllers">
          <button onClick={this.zoomIn}>Zoom In</button>
          <button onClick={this.zoomOut}>Zoom Out</button>
          <a  href={"https://cdn.huntakiller.com/huntakiller/s5"+this.props.image.img_url} target="_blank" rel="noopener noreferrer" download={this.props.image.img_name}>Download</a>

        </div>
          <div className="content-file" style={contentFileStyle}>
            <img src={"https://cdn.huntakiller.com/huntakiller/s5"+this.props.image.img_url} alt={this.props.image.img_name}/>
          </div>
        
        
			</div>
      {this.props.image.transcript  && (<div className="transcript-section mt-8 py-2" dangerouslySetInnerHTML={{ __html: this.props.image.transcript }}></div>)}
      </div>)

	}
}