import React, { Component } from 'react'

export default class ShiftDecoderDeviantTrackerView extends Component {

    constructor(props) {
        super(props);
        this.state = {
          shiftTextInput: "",
          shiftValue: 1,
          decryptedText:""
        };
    }

    handleChange = event => {
        
        this.setState({
          [event.target.id]: event.target.value
        });
     }


     handleShift = event => {
        
        /*console.log("handleShift");
        console.log(this.state.shiftValue);
        console.log(this.state.shiftTextInput);

        console.log(this.decrypt(this.state.shiftTextInput, (this.state.shiftValue)));*/

        this.setState({decryptedText:this.decrypt(this.state.shiftTextInput, (this.state.shiftValue))});

        //
     }


    decrypt(str, key) {
        var result = "";
        for (var i = 0; i < str.length; i++) {
            var c = str.charCodeAt(i);
            if      (65 <= c && c <=  90) result += String.fromCharCode(this.mod(c - 65 - key, 26) + 65);  // Uppercase
            else if (97 <= c && c <= 122) result += String.fromCharCode(this.mod(c - 97 - key, 26) + 97);  // Lowercase
            else result += str.charAt(i);  // Copy
        }
        return result;
    }

    mod(x, y) {
        return (x % y + y) % y;
    }

    render() {

        return (
            <div className="decoder-container" > 
            	<h4>Tools</h4>
            	<h2>{this.props.icon.name}</h2>
                <div className="relative">
                    <div className="gallery-viewer__back" onClick={this.props.goBack}>
                        <span className="gallery-viewer__back-arrow">&lt;</span>
                        <span className="gallery-viewer__back-text">GO BACK</span>
                    </div>
                </div>
                <p>Enter the encrypted message into the first blank box and choose the correct key shift number, then press 'Decrypt'.
    The key shift number is the number of letters the message shifts backward in the alphabet during encryption. For example: the encrypted letter ‘A’ with a shift of 3 is ‘X’ in plaintext.
    Types of Common Ciphers: Atbash, Autokey, Caesar, Grille, Playfair, QWERTY, Rail Fence, ROT13, Running Key, Shift, Vigenère</p>

                <div className="cta-contaniner mobile">
                    <div className="flex mb-6 ">
                        <label>Key Shift:</label>
                        <input type="number" min="1" max="26" className="cipher-input ml-4" onChange={this.handleChange} value={this.state.shiftValue} id="shiftValue"/>
                        <button className="bg-grey ml-8  text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={this.handleShift}>Decrypt</button>
                    </div>
                </div>

                <div className="flex justify-around mt-2   header-shift-decoder">
                    <div>Original</div>
                    <div className="desktop">Decrypted</div>
                </div>
                <div className="flex justify-around  text-area-container">
                    <div><textarea placeholder="Enter Text here" onChange={this.handleChange} id="shiftTextInput" value={this.state.shiftTextInput}/></div>
                    <div className="desktop"><textarea  placeholder="Decrypted text appears here" readOnly value={this.state.decryptedText}/></div>
                </div>

                 <div className="flex justify-around mt-2   header-shift-decoder mobile">
                    
                    <div>Decrypted</div>
                </div>
                <div className="flex justify-around  text-area-container mobile">
                    <div><textarea  placeholder="Decrypted text appears here" readOnly value={this.state.decryptedText}/></div>
                </div>



                <div className="cta-contaniner mt-4 desktop">
                    <div className="flex">
                        <label>Key Shift:</label>
                        <input type="number" min="1" max="26" className="cipher-input ml-4" onChange={this.handleChange} value={this.state.shiftValue} id="shiftValue"/>
                        <button className="bg-grey ml-8  text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={this.handleShift}>Decrypt</button>
                    </div>
                </div>
        	</div>)
    }




}
