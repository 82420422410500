import React, { Component } from 'react'

export default class AutomaticDecoderDeviantTrackerView extends Component {

	constructor(props) {
        super(props);
        this.state = {
          shiftTextInput: "",
          decryptedText:"",
          alphabet: {
	        q: 'a',
	        w: 'b',
	        e: 'c',
	        r: 'd',
	        t: 'e',
	        y: 'f',
	        u: 'g',
	        i: 'h',
	        o: 'i',
	        p: 'j',
	        a: 'k',
	        s: 'l',
	        d: 'm',
	        f: 'n',
	        g: 'o',
	        h: 'p',
	        j: 'q',
	        k: 'r',
	        l: 's',
	        z: 't',
	        x: 'u',
	        c: 'v',
	        v: 'w',
	        b: 'x',
	        n: 'y',
	        m: 'z'
	      }
        };
    }


    decrypt() {
      let result = ''
      for (var i = 0, j = this.state.shiftTextInput.length; i < j; i++) {
        const code = this.state.shiftTextInput.toLowerCase().charAt(i)
        const letter = this.state.alphabet[code]
        result += letter || code
      }
      //this.decryptedText = result
      
      this.setState({
          decryptedText: result
        });



    }


    handleChange = event => {
        
        /*this.setState({
          [event.target.id]: event.target.value
        });*/


        this.setState(
		  { [event.target.id]: event.target.value });

        
     }


     handleShift = event => {
        
        /*console.log("handleShift");
        console.log(this.state.shiftValue);
        console.log(this.state.shiftTextInput);

        console.log(this.decrypt(this.state.shiftTextInput, (this.state.shiftValue)));*/

        //this.setState({decryptedText:this.decrypt(this.state.shiftTextInput, (this.state.shiftValue))});

        //

        this.decrypt()
     }


    

    render() {

        return (<div className="decoder-container" > 
        	<h4>Tools</h4>
        	<h2>{this.props.icon.name}</h2>
          <div className="relative">
                <div className="gallery-viewer__back" onClick={this.props.goBack}>
                    <span className="gallery-viewer__back-arrow">&lt;</span>
                    <span className="gallery-viewer__back-text">GO BACK</span>
                </div>
            </div>
        	<p>Enter the encrypted message into the blank box, then press 'Decrypt'.
Types of Common Ciphers: Atbash, Autokey, Caesar, Grille, Playfair, QWERTY, Rail Fence, ROT13, Running Key, Shift, Vigenère</p>
		

			<div className="cta-contaniner mobile">
                    <div className="flex mb-6 ">
                        <button className="bg-grey ml-8  text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={this.handleShift}>Decrypt</button>
                    </div>
                </div>

                <div className="flex justify-around mt-2   header-shift-decoder">
                    <div>Original</div>
                    <div className="desktop">Decrypted</div>
                </div>
                <div className="flex justify-around  text-area-container">
                    <div><textarea placeholder="Enter Text here" onChange={this.handleChange} id="shiftTextInput" value={this.state.shiftTextInput}/></div>
                    <div className="desktop"><textarea  placeholder="Decrypted text appears here" readOnly value={this.state.decryptedText}/></div>
                </div>

                 <div className="flex justify-around mt-2   header-shift-decoder mobile">
                    
                    <div>Decrypted</div>
                </div>
                <div className="flex justify-around  text-area-container mobile">
                    <div><textarea  placeholder="Decrypted text appears here" readOnly value={this.state.decryptedText}/></div>
                </div>



                <div className="cta-contaniner mt-4 desktop">
                    <div className="flex">
                        <button className="bg-grey ml-8  text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={this.handleShift}>Decrypt</button>
                    </div>
                </div>
        	

        	</div>)
    }




}
