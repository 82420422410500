import React, { Component } from 'react'
import { devianttracker } from '../datas/season5/devianttracker.json';
import store from 'store';

export default class LoginDeviantTracker extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      password: "",
	      username: "",
	      error: false,
	    };
	}

	

  	handleChange = event => {
  		
	    this.setState({
	      [event.target.id]: event.target.value
	    });
	 }

	handleSubmit = event => {
		//console.log("submit")
	    event.preventDefault();
	    const password = this.state.password;
	    const username = this.state.username;
	    this.setState({ error: false });
	    
	    if(password.toLowerCase() !== devianttracker.password.toLowerCase() || username.toLowerCase() !== devianttracker.username){
	    	return this.setState({ error: true });
	    }else{
	    	store.set('dtpassword', password);
	    	this.props.validateLogin()
	    	//return this.setState({ isUnlocked: true });
	    }

	}

	handleKeyPress = (event) => {
	  if(event.key === 'Enter'){
	    //console.log('enter press here! ')
	    document.activeElement.blur();
	    event.preventDefault();
	  }
	}


    render() {

        return (<div className="login-dt h-full w-full flex justify-center items-center z-10"  onMouseDown={this.props.tapBack}>



                	<div className="login-box rounded-lg flex flex-col justify-center items-center p-4 pt-10 pb-8">
                	<div className="mr-2 "><img onMouseDown={this.props.tapBack} className=" close-icon-modal close-dtlogin"  alt="close-dt" onClick={this.props.tapBack} src="https://cdn.huntakiller.com/huntakiller/s5/dt-close-button.svg"/></div>
		        		<img src="https://cdn.huntakiller.com/huntakiller/s5/logo_devianttracker.svg" alt="logo" />
			        	<h2> Hello! To use DeviantTracker, please log in:</h2>
			        	

			        	<form className=" rounded  pt-6 " onSubmit={this.handleSubmit}>
			        		<div className="">
			        			<div className=" mb-3">
			        				<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.username}
	              onChange={this.handleChange} type="text" autoFocus id="username" placeholder="Username" onKeyPress={this.handleKeyPress}/>
	             					
	              				</div>
	              				<div className=" mb-3">
				        			<input className="shadow appearance-none border  w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password}
		              onChange={this.handleChange} type="password"  id="password" placeholder="Password" onKeyPress={this.handleKeyPress} autoComplete="on" />
		              				<p className="error mt-2">{this.state.error && "Oops! Something is wrong."} &nbsp;</p>
	              				</div>
			        		</div>
			        		<div className="flex items-center justify-between">
						      <button className="bg-grey mx-8  w-full text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" id="dtLoginButton" >
						        LOGIN
						      </button>
						     
						    </div>
			        	</form>

			        </div>




                </div>)
    }
}
