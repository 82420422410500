import React, { Component } from 'react'
import DesktopView from './views/DesktopView'
import { icons } from './datas/season5/icons.json';

class Desktop extends Component {

	constructor(props) {
	    super(props);

      	this.currentEpisode = 1
	    
	}


	render() {
		
		const currentEpisode = this.currentEpisode
		//console.log(episodeIndex)
		/*const iconsCurrentEpisode = icons.filter(icon => {
			return ((icon.episode <=  currentEpisode) )
		})*/

		//console.log(iconsCurrentEpisode)

		return (
		  
		  <DesktopView className="season5" icons={icons} currentEpisode={currentEpisode} history={this.props.history}  />
		  



		)

	}
}

export default Desktop