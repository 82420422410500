import React, { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player';

export default class AudioDeviantTrackerView extends Component {




    render() {

        return (<div className="audio-container" > 
        	<h4>Audio</h4>
        	<h2>{this.props.icon.name}</h2>
            <div className="relative">
                <div className="gallery-viewer__back" onClick={this.props.goBack}>
                    <span className="gallery-viewer__back-arrow">&lt;</span>
                    <span className="gallery-viewer__back-text">GO BACK</span>
                </div>
            </div>

        	<div>

        		{this.props.icon.audios.map(audio => 
        			<div className="player-item" key={audio}>
	        			<h3>{audio}</h3>
                        
	        			<ReactAudioPlayer key={audio} controlsList="nodownload"
						  src={"https://cdn.huntakiller.com/huntakiller/s5/"+audio} 
						  controls
						/>
					</div>
          			
        		)}  


        	</div>

        	</div>)
    }




}
