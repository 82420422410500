import React, { Component } from 'react'

export default class Planofaction extends Component{
  render (){
    return (
    <div className="rtfdoc planofaction">
		<h2><b>PLAN OF ACTION:</b></h2>
		<ul>
			<li><input type="checkbox" name="" value="1"/> <b>Conduct witness statements</b>
				<ul>
					<li>- Do not take their accounts at face value</li>
					<li>- Find supporting evidence/</li>
				</ul>
			</li>
			<li><input type="checkbox" name="" value="1"/> <b>Create approx. timeline based off statements</b>
				<ul>
					<li>- Fill in gaps as evidence comes in</li>
				</ul>
			</li>
			<li><input type="checkbox" name="" value="1"/> <b>Track suspects’ movements using timeline, scaled map, etc</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Check on status of autopsy and toxicology</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Get all waivers and legal forms signed ASAP</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Gather proof to support alibis</b>
				<ul>
					<li>- Search: house, car, place of employment</li>
					<li>- Question: relatives, friends, employers, bystanders</li>
					<li>- Access: security cams, email, digital logs, etc</li>
				</ul>
			</li>
			<li><input type="checkbox" name="" value="1"/> <b>Study autopsy, cross-reference w/ photos</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Study toxicology, cross-reference w/ autopsy</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Start eliminating suspects</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Make the arrest</b></li>
		</ul>

		<h2><b>WHEN YOU NEED TO TAKE A BREAK:</b></h2>
		<ul>
			<li><input type="checkbox" name="" value="1"/> <b>Clean desk</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Have a gin and tonic</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Turn on radio</b>
				<ul>
					<li>- Manic? 98.7FM</li>
					<li>- Groggy? 93.5FM</li>
				</ul>
			</li>
			<li><input type="checkbox" name="" value="1"/> <b>Have another gin and tonic</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Stretch for 30 min.</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Take a walk/run</b></li>
			<li><input type="checkbox" name="" value="1"/> <b>Read something that does not:</b>
				<ul>
					<li>- Relate to the case</li>
					<li>- Require extra brain power</li>
				</ul>

			</li>
			<li><input type="checkbox" name="" value="1"/> <b>Sleep by 1AM (please)</b></li>
		</ul>
    </div>
  );
  }
};