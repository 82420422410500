export function getUser(password, users) {
	var user;
	for (user of users) {
		if(user.pw.toLowerCase() === password.toLowerCase()){
			return user
		}
	}
	return null;
}

export function getSeasonUserFromPw(password, users){
	var user;
	for (user of users) {
		if(user.pw.toLowerCase() === password.toLowerCase()){
			return user
		}
	}
	return null;
}

export function doesSeasonPasswordExist(pw, users){
	var user;
	for (user of users) {
		if(user.pw.toLowerCase() === pw.toLowerCase()){
			return true
		}
	}
	return false;

}

export function getGetChildrenFromFolder(parentId, icons) {
	var childrens = [];
	var icon;
	for (icon of icons) {
		if(icon.parent === parentId){
			childrens.push(icon);
		}
	}
	return childrens;
}