import React, { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player';

export default class VideoDeviantTrackerView extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
          isVideo: true,
        };
	    
	}

  componentWillMount () {
    const script = document.createElement("script");
 
    script.src = "https://fast.wistia.com/embed/medias/rrx8ulnvvz.jsonp";
    script.async = true;
 
    document.body.appendChild(script);

    const script2 = document.createElement("script");
 
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;
 
    document.body.appendChild(script2);
  }

	handleTap = event => {

        console.log("handleTap")
        this.setState({isVideo:!this.state.isVideo});
     }

    render() {
    	   
         const divStyle = {
          
          position: "relative"
        };

        const divStyle2 = {
          height: "100%",
          margin:"0 auto",
          width:"950px",
          maxWidth:"100%"
        };

        const divStyle3 = {
          height: "100%",
          width:"100%",
          position: "relative"
        };

        const divStyle4 = {
          height:"100%",
          left:"0",
          opacity:"1",
          overflow:"hidden",
          position:"absolute",
          top:"0",
          transition:"opacity 200ms",
          width:"100%"
        };

        const imgStyle = {
          filter:"blur(5px)",
          height:"100%",
          objectFit:"contain",
          width:"100%"
        };
    	

        return (<div className="video-container" > 
        	<h4>Videos</h4>
          <h2>{this.props.icon.name}</h2>
          <div className="relative">
                    <div className="gallery-viewer__back" onClick={this.props.goBack}>
                        <span className="gallery-viewer__back-arrow">&lt;</span>
                        <span className="gallery-viewer__back-text">GO BACK</span>
                    </div>
                </div>
                
              {this.state.isVideo  && (
                  <div className="wistia_responsive_padding" style={divStyle}>
                   <div className="wistia_responsive_wrapper" style={divStyle2}>
                     <div className="wistia_embed wistia_async_rrx8ulnvvz videoFoam=true" style={divStyle3}>
                       <div className="wistia_swatch" style={divStyle4}>
                        <img src="https://fast.wistia.com/embed/medias/rrx8ulnvvz/swatch" style={imgStyle} alt="" aria-hidden="true" />
                       </div>
                     </div>
                   </div>
                 </div>


                )}


              {!this.state.isVideo  && (
                  <div className="player-item" >
                      <h3>dannyboy.mp3</h3>
                      <ReactAudioPlayer controlsList="nodownload"
                    src="https://cdn.huntakiller.com/huntakiller/s5/dannyboy.mp3"
                    controls
                  />
                </div>


                )}
               


               <div className="cta-contaniner mt-4">
                    <div className="flex">
                        <button className="bg-grey ml-8  text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={this.handleTap}>{this.state.isVideo  && ("Enhance")}{!this.state.isVideo  && ("Video")}</button>
                    </div>
                </div>

        	</div>)
    }




}
