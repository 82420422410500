import React, { Component } from 'react'

export default class Evidencechecklist extends Component{
  render (){
    return (
    <div className="rtfdoc evidencechecklist">
		<h2><input type="checkbox" name="" value="1"/> <b>Item: Bic Lighter</b></h2>
		<ul>
			<li>Evidence: Marker: A</li>
			<li>Location: In car, underneath driver’s seat</li>
			<li>Description: Blue, half full.</li>
		</ul>

		<h2><input type="checkbox" name="" value="1"/> <b>Item: Class Ring</b></h2>
		<ul>
			<li>Evidence: Marker: B</li>
			<li>Location: In car, on passenger side floor</li>
			<li>Description: 1998 Chestnut Falls High School class ring with initials CM</li>
		</ul>

		<h2><input type="checkbox" name="" value="1"/> <b>Item: Mint Tin</b></h2>
		<ul>
			<li>Evidence: Marker: C</li>
			<li>Location: In car, on backseat</li>
			<li>Description: Graphic illustration on front of a woman.</li>
		</ul>

		<h2><input type="checkbox" name="" value="1"/> <b>Item: Socks</b></h2>
		<ul>
			<li>Evidence: Marker: D</li>
			<li>Location: In trunk</li>
			<li>Description: Novelty print, blank piece of paper found inside.</li>
		</ul>

		<h2><input type="checkbox" name="" value="1"/> <b>Item: Necklace</b></h2>
		<ul>
			<li>Evidence: Marker: E</li>
			<li>Location: In car, underneath passenger side seat</li>
			<li>Description: Clasped shut, opal inset.</li>
		</ul>

		<h2><input type="checkbox" name="" value="1"/> <b>Item: Gift Bag</b></h2>
		<ul>
			<li>Evidence: Marker: F</li>
			<li>Location: In car, underneath passenger side seat</li>
			<li>Description: Blue tissue paper inside.</li>
		</ul>


		<h2><input type="checkbox" name="" value="1"/> <b>Item: Beanie Hat</b></h2>
		<ul>
			<li>Evidence: Marker: G</li>
			<li>Location: In car, on backseat</li>
			<li>Description: Knit hat in fair-isle pattern.</li>
		</ul>

		<h2><input type="checkbox" name="" value="1"/> <b>Item: Toy Doll</b></h2>
		<ul>
			<li>Evidence: Marker: H</li>
			<li>Location: In car, glove compartment</li>
			<li>Description: Plastic, found with handwritten note</li>
		</ul>

		<h2><input type="checkbox" name="" value="1"/> <b>Item: Plastic Comb</b></h2>
		<ul>
			<li>Evidence: Marker: I</li>
			<li>Location: Glove compartment</li>
			<li>Description: Plastic, fine-toothed comb.</li>
		</ul>

		<h2><input type="checkbox" name="" value="1"/> <b>Item: Spearmint Gum</b></h2>
		<ul>
			<li>Evidence: Marker: J</li>
			<li>Location: Beneath driver’s seat</li>
			<li>Description: Half-full pack of spearmint-flavored chewing gum.</li>
		</ul>

		<h2><input type="checkbox" name="" value="1"/> <b>Item: Car Freshener</b></h2>
		<ul>
			<li>Evidence: Marker: K</li>
			<li>Location: On floor of passenger side</li>
			<li>Description: Shaped like tree, “New Car” scent.</li>
		</ul>
    </div>
  );
  }
};